<template>
  <v-data-table
    :headers="headers"
    :items="reports"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
          <router-link :to="{ name:'pages-stock-employee-create' }">
          <v-btn
          class="primary"
          >สร้างใบเบิก
          </v-btn>
        </router-link>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page-1)*serverOptions.itemsPerPage) + index +1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:item.status="{ item }">
      <span v-if="item.status == 'Y'">ตัดเรียบร้อย</span>
      <span v-if="item.status != 'Y'">-</span>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_report from '../../../services/report'

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions : {
      page: 1,
      itemsPerPage: 10,

    },
    page : 1,
    size : 10,
    total : 0,
    reports: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      { text: 'เลขที่ใบเบิกสินค้า', value: 'stock.no_stock' , sortable: false},
      { text: 'ชื่อสินค้า', value: 'product.product_name' , sortable: false},
      { text: 'จำนวนขอเบิก', value: 'amount' },
      { text: 'วันที่ขอเบิก', value: 'created_at' },
      { text: 'เบิกโดย', value: 'stock.employee.employee_name', sortable: false },
      { text: 'ตัดสต๊อคโดย', value: 'stock.employee_admin.employee_name', sortable: false },
      { text: 'สถานะ', value: 'status' },
      // { text: 'แก้ไขข้อมูล', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
    defaultItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    serverOptions : {
      handler(){
        this.getReport()
      },
    },
    search : {
      handler(){
        this.getReport()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getReport()
  },

  methods: {

    editItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      instance_report.employeeTypeDestroy(this.editedItem).then(res => {

        this.reports.splice(this.editedIndex, 1)
      }).catch(err => {

      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        instance_report.employeeTypeUpdate(this.editedItem)
          .then(res => {
            Object.assign(this.employees_types[this.editedIndex], this.editedItem)
          }).catch(err => {

        });
      } else {
        instance_report.employeeTypeStore(this.editedItem)
          .then(res => {
            this.employees_types.push(this.editedItem)
          }).catch(err => {

        });
      }
      this.close()
    },
    getReport() {
      this.$store.state.isLoading = true;
      var data = {
        search : this.search,
        size : this.serverOptions.itemsPerPage,
        page : this.serverOptions.page,
        sortBy : this.sortBy ?? "",
        sortDesc : this.sortDesc ?? ''
      }

      instance_report.getReportProductAmount(data).then(res => {
        this.reports = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false;
      }).catch(err => {
        this.$store.state.isLoading = false;
      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
