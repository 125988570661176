/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  getHomeReport(data) {
    return api.get(`report/home?start_date=${data.date[0]}&end_date=${data.date[1]}`)
  },

  getOverAll(data) {
    return api.get(`report/get_overall?search=${data.search}&date=${data.date}&status=${data.status}&type=${data.type}`)
  },
  getProductMoveMent(data) {
    return api.get(`report/get_product_movement?search=${data.search}&date=${data.date}&status=${data.status}&size=${data.size}&page=${data.page}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  getReportTaxNumber(data) {
    return api.get(`report_tax_number/get_report_tax_number?search=${data.search}&date=${data.date}&status=${data.status}&size=${data.size}&page=${data.page}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  getReportBookingOrder(data) {
    return api.get(`report_booking_order/get_report_booking_order?search=${data.search}&date=${data.date}&status=${data.status}&size=${data.size}&page=${data.page}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  getReportCredit(data) {
    return api.get(`report_credit/get_report_credit?search=${data.search}&date=${data.date}&status=${data.status}&size=${data.size}&page=${data.page}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  getReportCreditMoney(data) {
    return api.get(`report_credit_money/list?search=${data.search}&date=${data.date}&status=${data.status}&size=${data.size}&page=${data.page}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  getReportManageSell(data) {
    return api.get(`report_manage_sell/list?search=${data.search}&date=${data.date}&status=${data.status}&size=${data.size}&page=${data.page}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  getReportOrderScanBill(data) {
    return api.get(`order_scan_billing/get_order_scan?search=${data.search}&date=${data.date}&status=${data.status}&size=${data.size}&page=${data.page}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  getReportProductAmount(data) {
    return api.get(`stock_report_product_amount/get_report?search=${data.search}&page=${data.page}&size=${data.size}&size=${data.size}&page=${data.page}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  showEmployeeType(data) {
    return api.get(`/order?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  employeeTypeStore(data) {
    const getFormData = data

    const params = new FormData()
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }

    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post('employee_type/store', params, {})
  },

  employeeTypeUpdate(data) {
    const getFormData = data

    const params = new FormData()
    params.append('_method', 'PUT')
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }

    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`employee_type/update/${data.employee_type_id}`, params, {})
  },

  employeeTypeDestroy(data) {
    const getFormData = data

    const params = new FormData()

    // params.append('_method', 'DELETE');
    for (const key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key])
      }
    }

    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }

    return api.delete(`employee_type/delete/${data.employee_type_id}`, params, {})
  },

}
